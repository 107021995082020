import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import Header from '../Components/Header'
import Footer from '../Components/Footer'

const About = () => {
  return (
    <div>
      <Header/>
      <div className="about-container">
        <div className="about-left-section">
          <img src="prof_pic.jpg" alt="Profile" className="about-profile-image" />
        </div>
        <div className="about-right-section">
          <div className="about-name-section">
            <h1>Danylo Lopatnov</h1>
            <h2>Software Developer</h2>
          </div>
          <div className="about-paragraph-section">
            <p>I'm a full-stack software developer, specializing in back-end languages such as C++ and C#. I developed this website using React.js, Google Firebase database and hosting services. I have also created mobile apps using various .NET frameworks and Node.js servers.</p>
            <p>I enjoy participating in cybersecurity CTF (capture the flag) events and have a dedicated team with which I practice weekly. In my free time I challenge myself to ride excessive distances on my bike to enjoy sunrises while camping. I'm also highly curious in any mechanical or electrical systems.</p>
            <Link to="/projects" className="about-see-projects-button"> See my projects </Link>
          </div>
          <div className="about-education-section">
            <h2>Education</h2>
            <p>- IT Step Academy <br/> - Birmingham City University</p>
          </div>
          <div className="about-social-media-icons">
            <a href="https://www.instagram.com/danya.lv/" target="_blank" rel="noopener noreferrer">
              <img class="social-media-icons" src="/instagram.png" alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/in/danylo-lopatnov" target="_blank" rel="noopener noreferrer">
              <img class="social-media-icons" src="/linkedin.png" alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default About;