import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ComputersPage from './Pages/ComputersPage';
import About from './Pages/AboutPage';
import Projects from './Pages/ProjectsPage';
import Contact from './Pages/ContactPage';
import { useMediaQuery } from 'react-responsive'

const App = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isDesktopOrLaptop  = useMediaQuery({ query: '(min-width: 1224px)' })

  return (
      <div> 
        <Routes>
          {isTabletOrMobile && <Route path="/" element={<About />} />}
          {isDesktopOrLaptop && <Route path="/" element={<ComputersPage />} />}
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
  );
};

export default App;
