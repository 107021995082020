import React from 'react';
import { Link } from 'react-router-dom'; 
import '../styles.css';
import { useMediaQuery } from 'react-responsive'

const Header = () => {
  const isDesktopOrLaptop  = useMediaQuery({ query: '(min-width: 1224px)' })

  return (
    <div>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <header className="header-navbar">
        <nav className="header-nav-links">
          {isDesktopOrLaptop && <Link to="/">Home</Link>}
          {isDesktopOrLaptop && <span className="header-divider">|</span>}
          <Link to="/about">About</Link>
          <span className="header-divider">|</span>
          <Link to="/projects">Projects</Link>
          <span className="header-divider">|</span>
          <Link to="/contact">Contact</Link>
        </nav>
      </header>
    </div>
  );
};

export default Header;