import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, MeshReflectorMaterial, BakeShadows } from '@react-three/drei'
import { EffectComposer, Bloom, DepthOfField, ToneMapping } from '@react-three/postprocessing'
import { easing } from 'maath'
import { suspend } from 'suspend-react'
import { Instances, Computers } from '../Components/ComputersScene'
import { Link } from 'react-router-dom'
import React, { useEffect, useRef } from 'react';

function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, color: 'white'}}>
      <div style={{ position: 'absolute', top: 60, left: 60, fontSize: '20px' }}>Click a TV</div>
      <div style={{ position: 'absolute', bottom: 60, left: 60, fontSize: '20px' }}>Danylo Lopatnov</div>
    </div>
  )
}

export default function ComputersPage() {
  const imageRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        if (rect.top <= windowHeight) {
          imageRef.current.classList.add('visible');
          window.removeEventListener('scroll', handleScroll); // Optional: remove listener after animation
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on load if the element is already in view

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Overlay/>
      <Canvas style={{height : "100vh"}} shadows dpr={[1, 1.5]} camera={{ position: [0, 1, 20], fov: 45, near: 1, far: 20 }} eventSource={document.getElementById('root')} eventPrefix="client">
        {/* Lights */}
        <color attach="background" args={['black']} />
        <hemisphereLight intensity={0.15} groundColor="white" />
        <spotLight decay={0} position={[20, 20, 20]} angle={0.12} penumbra={1} intensity={1} castShadow shadow-mapSize={1024} />
        {/* Main scene */}
        <group position={[-0.4, -1, 2]} rotation={[0, -0.3, 0]}>
          <Instances>
            <Computers scale={0.5} />
          </Instances>

          <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]}>
            <planeGeometry args={[20, 50]} />
            <MeshReflectorMaterial
              blur={[300, 30]}
              resolution={2048}
              mixBlur={1}
              mixStrength={50}
              roughness={0.7}
              depthScale={1.2}
              minDepthThreshold={0.4}
              maxDepthThreshold={1.4}
              color="#20301C"
              metalness={0.8}
            />
          </mesh>

          <pointLight distance={2} intensity={1} position={[-0.15, 1.3, 0]} color="white" />
        </group>
        {/* Postprocessing */}
        <EffectComposer disableNormalPass>
          <Bloom luminanceThreshold={0} mipmapBlur luminanceSmoothing={0.0} intensity={0.3} />
        </EffectComposer>
        <CameraRig />
        <BakeShadows />
      </Canvas>
      <div className="computers-description-container">
        <div class="computers-description-text">
          <h1 className="computers-description-h1">Code Anything</h1>
          <p className="computers-description-p">I will program everything you want - websites, desktop programs, mobile apps, databases, custom frameworks, even 3D models!</p>
          <Link to="/projects" className="computers-see-projects-button"> See my projects </Link>
        </div>
        <div class="computers-profile-image" ref={imageRef}>
          <img src="prof_pic.jpg" alt="Profile"/>
        </div>
      </div>
    </div>
  )
}

function CameraRig() {
  useFrame((state, delta) => {
    easing.damp3(state.camera.position, [-1 + (state.pointer.x ) / 3, (1 + state.pointer.y) / 2, 5.5], 0.5, delta)
    state.camera.lookAt(0, 0, 0)
  })
}
